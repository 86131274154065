import DateInput from "@src/libs/ui/DateInput";
import TextInput from "@src/libs/ui/TextInput";
import OrderStore from "@src/model/order-repo";
import { QrcodeOutline, XCircle, InformationCircle, Search,Calendar,Refresh } from "heroicons-react";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import { Plugins } from "@capacitor/core";
import ConfigStore from "@src/model/config";
import ModalScan from "../scanner/ModalScan";
import ModalInfo from "../ModalInfo";
import ClipLoader from "react-spinners/ClipLoader";
import useSound from 'use-sound';
import Order from "@src/pages/Order";
import { dateFormat } from "@src/libs/utils/stringFormat";
// const QRScanner = require('QRScanner');
const { Device } = Plugins;


export default observer(() => {

  var context : any
  window.onload = function() {
    context = new AudioContext();
  }

  // const playAudio = function() {
  //   const audioEl = document.getElementById("audio-element")
  //   console.log("1211")
  //   if(!!audioEl){
  //     const audioE2 :HTMLMediaElement  = audioEl as HTMLMediaElement

  //     console.log("12")
  //     var playPromise = audioE2.play()
  //     console.log(playPromise)
  //     if (!!playPromise) {
  //       playPromise.then(function() {
  //         console.log("Played")
  //       }).catch(function(error:any) {
  //         console.log("Error")
  //       });
  //     }

      
  //   }
    
  // }
  
  
  const useAudio = (url:any) => {
    const [audio] = useState(new Audio(url));
    // context.resume().then(() => {
      audio.play();
      console.log('Playback resumed successfully');
    // })
    console.log('Playback');
    
  
    // useEffect(() => {
    //   audio.addEventListener('ended', () => setPlaying(false));
    //   return () => {
    //     audio.removeEventListener('ended', () => setPlaying(false));
    //   };
    // }, []);
  };







  //const playsound = useAudio("../src/assets/sounds/order.mp3");

  const meta = useLocalObservable(() => ({
    qrcode: false,
    date: false,
    search: false,
  }));

  //const soundUrl = '../src/assets/sounds/order.mp3';

//  const [played] = useSound(soundUrl);
  
  const scan = async () => {
    if (!window || !window.QRScanner) {
      return null;
    }
    let QRScanner = window.QRScanner;
    const hidden = () => {
      QRScanner.hide();
      QRScanner.cancelScan();
      QRScanner.pausePreview();
      runInAction(() => {
        ConfigStore.statusCamera = "hidden";
      });
    };
    await QRScanner.prepare((_, status: QRScannerStatus) => {
      if (status.authorized) {
        if (ConfigStore.statusCamera === "hidden") {
          ConfigStore.showModalBarcode=3
          // camera permission was granted
          QRScanner.useFrontCamera();
          // start scanning
          QRScanner.scan((_, text: string) => {
            if (!!text) {
              runInAction(() => {
                OrderStore.filter = text;
                OrderStore.filterDate = "";
                ConfigStore.showModalBarcode =0;

                OrderStore.scan()
                hidden();

                // OrderStore.filter = text;
                // OrderStore.filterDate = "";
                // ConfigStore.showModalBarcode =0;
                // OrderStore.idDetailOrder = OrderStore.getList[0].id
              });
              hidden();
            }
          });
          QRScanner.show();
          runInAction(() => {
            ConfigStore.statusCamera = "front";
          });
        } else if (ConfigStore.statusCamera === "front") {
          // camera permission was granted
          QRScanner.useBackCamera();
          // start scanning
          QRScanner.scan((_, text: string) => {
            if (!!text) {
              runInAction(() => {
                OrderStore.filter = text;
                OrderStore.filterDate = "";
                ConfigStore.showModalBarcode =0;
                OrderStore.idDetailOrder = OrderStore.getList[0].id
              });
              hidden();
            }
          });
          QRScanner.show();
          runInAction(() => {
            ConfigStore.statusCamera = "back";
          });
        } else {
          hidden();
        }
      } else if (status.denied) {
        // camera permission was permanently denied
        // you must use QRScanner.openSettings() method to guide the user to the settings page
        // then they can grant the permission from there
      } else {
        // permission was denied, but not permanently. You can ask for permission again at a later time.
      }
    });
  };
  useAsyncEffect(async () => {
    const info = await Device.getInfo();
    if (info.platform !== "web") {
      runInAction(() => (meta.qrcode = true));
    }
  }, []);

  return (
    <div className="flex items-center pr-2">
      
      {/* {meta.qrcode && (  */}
        <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 ml-0 h-8">
          <button
            className="p-1 rounded-md flex justify-center items-center ripple"
            onClick={
              ()=>{
                OrderStore.openProductPicker = false;
                if(meta.qrcode){
                  if(ConfigStore.socketIsConnected===1 && ConfigStore.selectedTotem.room!==""){
                    ConfigStore.showModalBarcode=1
                  }else{
                    scan()
                  }
                }else if(ConfigStore.socketIsConnected===1 && ConfigStore.selectedTotem.room!==""){
                  ConfigStore.showModalBarcode=2
                }else{
                  alert("Totem disconnected. Please select active totem.")
                }
                
              }
            }
          >
            <QrcodeOutline size={24} className="text-gray-600" />
          </button>
        </div>
      {/* )} */}

      <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 ml-0 h-8 w-16 self-center items-center justify-center">
          <button
            className="p-1 rounded-md flex justify-center items-center ripple w-16"
            disabled={OrderStore.loading}
            onClick={
            ()=>{
              //playAudio()
              OrderStore.load();
            }
          }
          >
            {OrderStore.loading ? 
            <ClipLoader color={"#2b6cb0"} loading={true} size={24} />
            :
            <Refresh size={24} className="text-gray-600" />
            }
            
          </button>
          {/* <audio id="audio-element"
          
          autoPlay={false}
          >
            <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"
            // https://assets.coderrocketfuel.com/pomodoro-times-up.mp3
            ></source>
          </audio> */}
        </div>


      <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 h-8">
      {meta.date || OrderStore.filterDate!="" ? (
        <>
        <DateInput
          className="p-1 w-32 lg:w-56"
          value={OrderStore.filterDate}
          onChange={(e) =>{
            runInAction(() => {
              OrderStore.list=[]
              OrderStore.filterDate = e.target.value
              OrderStore.load();
            })
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
        {/* <button
          className="p-2 rounded-md flex justify-center items-center ripple"
          onClick={() => runInAction(() => {
            (OrderStore.filterDate = "")
            meta.date=false
          })}
        >
          <XCircle size={20} className="text-gray-600" />
        </button> */}
        </>
      ):(
        <button
          className="p-2 rounded-md flex justify-center items-center ripple"
          onClick={() => runInAction(() => {
            (OrderStore.filterDate = "")
            meta.date=true
          })}
        >
          <Calendar size={20} className="text-gray-600" />
        </button>
      )}
      </div>
      <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 h-8">
        {meta.search || OrderStore.filter!="" ? (
          <>
        <TextInput
          className="p-1 mr-1 w-32 lg:w-56"
          placeholder="Search"
          value={OrderStore.filter}
          onChange={(e) =>
            runInAction(() => {
              (OrderStore.filter = e.target.value)
              if(OrderStore.filter==""){
                OrderStore.filterDate = dateFormat(new Date(), "yyyy-MM-dd");
              }
            })
          }
        />
        <button
          className="p-2 rounded-md flex justify-center items-center ripple"
          onClick={() => runInAction(() => {
            (OrderStore.filter = "")
            OrderStore.filterDate = dateFormat(new Date(), "yyyy-MM-dd");
            meta.search=false
          })}
        >
          <XCircle size={20} className="text-gray-600" />
        </button>
        </>
        )

        :
        (<button
          className="p-2 rounded-md flex justify-center items-center ripple"
          onClick={() => runInAction(() => {
            (OrderStore.filter = "")
            meta.search=true
          })}
        >
          <Search size={20} className="text-gray-600" />
        </button>)
        
      }
        
        
      </div>
      {true && ( 
        <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 ml-0 h-8">
          <button
            className="p-1 rounded-md flex justify-center items-center ripple"
            onClick={
              ()=>{
                ConfigStore.showModalBarcode=10
              }
            }
          >
            <InformationCircle size={24} className="text-gray-600" />
          </button>
        </div>
      )}
      <ModalInfo/> 
      <ModalScan/>
      {meta.qrcode && ( 
        <ModalInfo/>
      )}
    </div>
  );
});
