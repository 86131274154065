import { AppConfig } from "@src/config/app";
import api from "@src/libs/utils/api";
import ConfigStore from "@src/model/config";

const checkToken = async (token: string) => {
  let res: any = await api({
    url: `${AppConfig.server}index.php?r=apiAuthPOS/validation`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  console.log(JSON.stringify({
    url: `${AppConfig.server}index.php?r=apiAuthPOS/validation`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }))
  

  if (typeof res === "object") {
    if (!!res.status) {
      return res.data;
    } else {
      alert(res.msg);
    }
  }
  return {};
};

const getAddOnQueue = async () => {
  let url = `${ConfigStore.server_url}/index.php?r=apiServicePOS/getAddOnQueue`;
  const res:any= await api({
    url,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      id_outlet : ConfigStore.id_outlet,
    },
  });

  // console.log(JSON.stringify({
  //   url,
  //   method: "post",
  //   data: {
  //     appName: ConfigStore.app_name,
  //     appToken: ConfigStore.app_token,
  //     client: ConfigStore.id_client,
  //     userToken: ConfigStore.user_token,
  //     id_outlet : ConfigStore.id_outlet,
  //   },
  // }))
  
  if (!!res) {
    if(!!res.status){
      return res.status;
    }
  }
  // console.log(JSON.stringify(res))
  return false;
};


const postPrintLog = async (sales_order_id:number) => {
  let url = `${ConfigStore.server_url}/index.php?r=apiServicePOS/postPrintLog`;
  
  console.log(JSON.stringify({
    url,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      user_id: ConfigStore.user_id,
      id_sales_order:sales_order_id
    },
  }))
  const res:any= await api({
    url,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      user_id: ConfigStore.user_id,
      id_sales_order:sales_order_id
    },
  });
  console.log(JSON.stringify(res))
  if (!!res) {
    return res.count;
  }
  return 0;
};

const SessionApi = {
  checkToken,
  getAddOnQueue,
  postPrintLog
};

export default SessionApi;
