import fuzzyMatch from "@src/libs/utils/fuzzyMatch";
import { runInAction } from "mobx";
import ProductApi from "../api/ProductApi";
import { Model } from "../libs/model/model";
import ConfigStore from "./config";
import {
  CategorySingle,
  ProductSingle,
  SubCategorySingle,
} from "./product-single";

export class ProductRepository extends Model {
  categories: CategorySingle[] = CategorySingle.hasMany(this);
  subcategories: SubCategorySingle[] = SubCategorySingle.hasMany(this);
  list: ProductSingle[] = ProductSingle.hasMany(this);
  activeCategory: number = 0;
  activeSubCategory: number = 0;
  filter = "";

  get listCategories() {
    return this.categories;
  }
  get listSubCategories() {
    let active = this.activeCategory;
    let sub = this.subcategories;
    if (!active) return [];
    return sub.filter((x) => x.id_category === active);
  }
  get listProduct() {
    return this.list.filter((item) => {
      let match = true;
      let f = this.filter.toLowerCase();
      if (this.activeCategory !== 0) {
        match = item.id_category === this.activeCategory;
      }
      if (!!f) {
        let m = true;
        if (!!item.product_name) {
          m = fuzzyMatch(f, item.product_name.toLowerCase());
        }
        if (!!item.code) {
          m = m || fuzzyMatch(f, item.code.toLowerCase());
        }
        match = !!match && !!m;
      }
      return match;
    });
  }

  //  searchProduct(name:String) {
  //   return this.list.filter((item) => {
  //     let match = true;
  //     let f = this.filter.toLowerCase();
  //     if (this.activeCategory !== 0) {
  //       match = item.id_category === this.activeCategory;
  //     }
  //     if (!!f) {
  //       let m = true;
  //       if (!!item.product_name) {
  //         m = fuzzyMatch(f, item.product_name.toLowerCase());
  //       }
  //       if (!!item.code) {
  //         m = m || fuzzyMatch(f, item.code.toLowerCase());
  //       }
  //       match = !!match && !!m;
  //     }
  //     return match;
  //   });
  // }

  public async reload() {
    

    await ProductApi.getCategory().then((res) => {
      if(res.length>0){
        
        const categories: Array<CategorySingle> = [
          new CategorySingle()._loadJSON({
            id: 0,
            name: "All",
            sequence: 0,
          }),
        ];

        let product: any[] = [];
        
        for (let item of res) {
          categories.push(
            new CategorySingle()._loadJSON({
              id: item.id,
              name: item.category,
              sequence: item.sequence,
            })
          );

          for (let prod of item.product) {
            product.push({
              id: prod.id,
              code: prod.code,
              description: prod.description,
              id_category: item.id,
              product_name: prod.product_name,
              image: ConfigStore.server_url + "/" + prod.url_pic,
              price: prod.normal_price,
              valid_price: prod.valid_price,
              complement: prod.complements,
            });
          }
        }

        this._loadJSON({
          categories: categories,
          list: product,
          activeCategory: categories[0].id,
        });
      }
    });
    
    
  }

  public async loadProductMember(member: number) {
    
    const categories: Array<CategorySingle> = [
      new CategorySingle()._loadJSON({
        id: 0,
        name: "All",
        sequence: 0,
      }),
    ];

    let product: any[] = [];
    await ProductApi.getCategory(member).then((res) => {
      res.forEach((item: any) => {
        categories.push(
          new CategorySingle()._loadJSON({
            id: item.id,
            name: item.category,
            sequence: item.sequence,
          })
        );
        item.product.forEach((prod: any) => {
          product.push({
            id: prod.id,
            code: prod.code,
            description: prod.description,
            id_category: item.id,
            product_name: prod.product_name,
            image: ConfigStore.server_url + "/" + prod.url_pic,
            price: prod.normal_price,
            valid_price: prod.valid_price,
            complement: prod.complements,
          });
        });
      });
    });
    return {
      categories: categories,
      list: product,
      activeCategory: categories[0].id,
    };
  }

  setCategory(id: number) {
    this._loadJSON({
      activeCategory: id,
    });
  }

  init(){
    runInAction(()=>{
      this.categories = [];
      this.subcategories = [];
      this.list = [];
      this.filter="";
    })
  }
}

const ProductStore = ProductRepository.create({
  localStorage: true,
  storageName: "ProductRepository",
});
export default ProductStore;
